























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Spinners from "@/components/utilities/Spinners.vue";
import NotFound from "@/views/NotFound.vue";
import eventHub from "@/event-hub";
import store from "@/store";

import VerticalCarousel from "@/components/shared/VerticalCarousel.vue";
import VideoComponent from "@/components/shared/VideoComponent.vue";
import Home from "@/components/home/DefaultHomePage.vue";
import SponsorSwimLaneSwiper from "@/components/home/section/SponsorSwimLaneSwiper.vue";

import featuredContentVuexModule from "@/store/vuex-modules/featuredContent";
import featuredVideoVuexModule from "@/store/vuex-modules/getFeaturedVideo";
import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";

import { SessionDataArray } from "@/types/interfaces";

const featuredContentStore = getModule(featuredContentVuexModule);
const featuredVideoStore = getModule(featuredVideoVuexModule);
const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);

const sessionStore = getModule(sessionVuexModule);
const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        Home,
        Spinners,
        VideoComponent,
        VerticalCarousel,
        SponsorSwimLaneSwiper,
        NotFound,
        AttendeeSwimlane: () => {
            const returnResult = store.getters.getPageOptions("home").section
                ?.attendeeSwimlane?.componentToLoad;
            return import(`@/components/attendees/swimlanes/${returnResult}`);
        }
    },
    directives: {
        addSvg: {
            inserted() {
                eventHub.$emit("HomeLobbySvgInserted");
            }
        }
    }
})
export default class Image3DPage extends Vue {
    isLoading = true;
    isLoadingVideo = false;
    hasLobbyData = false;
    isAttendeeLoading = true;
    loadFetauredContent = false;
    featuredSession: any = [];
    publicPath = process.env.BASE_URL;
    isFeaturedDataLoading = true;

    /**
     * Computed
     */
    get notProduction() {
        return Boolean("production" !== process.env.NODE_ENV);
    }

    get pageOptions() {
        return this.$store.getters.getPageOptions("home") || {};
    }

    get featuredContent() {
        return featuredContentStore.featuredContent;
    }

    get sessionData(): SessionDataArray {
        return sessionStore.sessionPromos ? sessionStore.sessionPromos : [];
    }

    get featuredVideo() {
        return featuredVideoStore.featuredVideo;
    }
    get attendeeSwimlane() {
        return featuredAttendeeStore.featuredAttendeeSwimlanes
            .flatMap((item: any) => {
                return item.data;
            })
            .slice(0, 4);
    }
    get attendeeSwimlanes() {
        return this.attendeePageOptions.swimLanes
            ? this.attendeePageOptions.swimLanes
            : [];
    }

    get attendeePageOptions() {
        return this.$store.getters.getPageOptions("attendees");
    }

    get homeLabel() {
        return this.$store.getters.homeLabel;
    }

    get logoImage() {
        return this.publicPath + "logos/partnership.svg";
    }
    get homeBG(): string {
        return this.publicPath + "home-bg/" + this.pageOptions.imageBackground;
    }

    get sponsorSwimlane() {
        const partner = tradeshowStore.tradeshowCompanies.partner;
        const premier = tradeshowStore.tradeshowCompanies.premier;
        const title = tradeshowStore.tradeshowCompanies.title;
        // try {
        //     return [...partner, ...premier, ...title];
        // } catch (err) {
        //     console.error(err);
        //     return [];
        // }
        return partner;
    }

    /**
     * Lifecycle
     */
    created() {
        this.isLoading = false;
        const newPromises = [
            featuredContentStore.getFeaturedContent(),
            featuredVideoStore.getFeaturedVideo(),
            sessionStore.getSessionData({
                tableName: this.$store.getters.awsConfig.sessionTable,
                isDemoData: false
            }),
            tradeshowStore.getTradeshowCompanies()
        ];

        Promise.allSettled(newPromises)
            .then(() => {
                this.featuredSession = [...this.featuredContent];
                this.isLoadingVideo = false;
            })
            .finally(() => {
                this.loadFetauredContent = true;
                this.isFeaturedDataLoading = false;
            });

        featuredAttendeeStore
            .getFeaturedAttendees(this.attendeeSwimlanes)
            .finally(() => (this.isAttendeeLoading = false));
    }

    /**
     * Methods
     */

    myImage(img: string) {
        return img ? img : require("../../assets/avatar2.png");
    }
    routeTo(routeString: string) {
        this.$router.push({
            name: routeString
        });
    }
}
